import { useEffect } from "react";
import useGeoLocation from "../../hooks/useLocation";
import { Button, IconButton, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { LocationOn } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { fetchGeoLocationByCoordinates } from "../../redux/actions/commonActions";

const GeoLocation = ({ onSideBar = false }) => {

    const [geoLocationData, fetchGeoLocation] = useGeoLocation();
    const geoLocState = useSelector(state => state?.common?.geoLocationData);
    const dispatch = useDispatch();

    useEffect(() => {
        if (geoLocationData) {
            dispatch(fetchGeoLocationByCoordinates(geoLocationData))
        }
    }, [geoLocationData]);


    return (
        navigator.geolocation ?
            <>
                {onSideBar ?
                    <ListItemButton onClick={() => fetchGeoLocation()} >
                        <ListItemIcon>
                            <LocationOn />
                        </ListItemIcon>
                        <ListItemText primary={geoLocState?.address?.county ?? ""} />
                    </ListItemButton >
                    :
                    <Button color="inherit" onClick={() => fetchGeoLocation()}>
                        {geoLocState?.address?.county ? <div className='text-sm px-2 capitalize'>{geoLocState.address.county}</div> : null}
                        <LocationOn />
                    </Button>
                }
            </>

            :
            <></>
    )

}
export default GeoLocation;