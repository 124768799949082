import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmationDialog = ({
    show,
    title = "",
    content = "",
    cancelLabel = "Close",
    confirmLabel = "Confirm",
    onClose = () => { },
    onConfirm = () => { },
    onCancel = () => { }
}) => {

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
        onClose();
    };

    React.useEffect(() => {
        setOpen(show);
    }, [show])

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{sx:{borderRadius:3}}}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>{cancelLabel}</Button>
                    <Button onClick={onConfirm} autoFocus>
                        {confirmLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        </ >
    );
}


export default ConfirmationDialog;