export const URL = {
    ORGANIZATIONS: 'organizations',
    BUSINESS_CATEGORIES: 'business-categories',
    SERVICE_CATEGORIES: 'service-categories',
    PRODUCT_CATEGORIES: 'product-categories',
    SERVICES: 'services',
    PRODUCTS: 'products',
    SERVICES_BY_ORG_ID: 'services-by-org-id',
    SCHEDULES: 'temporary-services',
    SCHEDULE_BY_SERVICE_ID: 'find-temporary-services-by-service-id',
    AVAILABILITIES_BY_SCHEDULE_ID: 'availabilities/temporary/service',
    PROVIDERS_BY_SERVICE_ID: 'providers-by-service-id',
    GUEST_USER: 'social-users',
    GUEST_VERIFY_OTP: 'verifyOtp',
    EVENTS: 'events',
    WAITLIST: 'waitlists',
    WAITLIST_CONFIRM: 'waitlists/confirm',
    CUSTOMER_SERVICE_STATUS: "customer/service/status",
    SURVEYS: 'surveys',
    SURVEY_ANSWERS: 'survey-answers',
    FEEDBACK_ANSWERS: 'feedback-answers',
    FIND_BY_MAIL: 'customers/find-by-email',
    FIND_EVENTS_BY_CUSTOMER_ID: 'find-events-by-customer-id',
    TWILIO_TOKEN: 'twilio/token',
    ZOOM_TOKEN: 'zoom/token',
    CUSTOMERFLOW_STATUS: 'customer/flow/status',
    CUSTOMER_CHECKIN: 'customer-checkIn',
    PROFILE: "c-aws-users",
    OTP_LOGIN_REQUEST: "quickLoginRequest",
    OTP_LOGIN_VERIFY: "quickLoginVerify",
    RAZORPAY_CALLBACK : "verify-payments/razorpay",
    PAYPAL_CALLBACK : "verify-payments/paypal",
    PAYMENT_MODE : "payment-mode",
    SUPPORT_REQUEST : "support-request",

}