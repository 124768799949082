import { useEffect, useState } from "react";
import { fetchOrganizationById, fetchProvidersByServiceId, fetchScheduleById, fetchSchedulesByServiceId, fetchServiceById } from "../../redux/actions/apiActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PrimaryLayout from "../../layout/Layout";
import { Alert, Box, Chip, Icon, useMediaQuery } from "@mui/material";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import SkeletonCard from "../../components/Loaders/SkeletonCard";
import ScheduleCard from "./ScheduleCard";
import UserForm from "./UserForm";
import HorizontalItems from "../../components/HorizontalItems/HorizontalItems";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ServiceCard from "./ServiceCard";
import { useTheme } from "@emotion/react";

const Service = () => {
  const dispatch = useDispatch();
  const serviceDetails = useSelector(state => state.api.serviceDetails);
  const orgDetails = useSelector(state => state.api.orgDetails);
  const serviceDetailsLoading = useSelector(state => state.api.serviceDetailsLoading);
  const scheduleList = useSelector(state => state.api.scheduleList);
  const scheduleListLoading = useSelector(state => state.api.scheduleListLoading);
  const providerList = useSelector(state => state.api.providerList);
  const providerListLoading = useSelector(state => state.api.providerListLoading);

  const scheduleDetails = useSelector(state => state.api.scheduleDetails);
  const scheduleDetailsLoading = useSelector(state => state.api.scheduleDetailsLoading);

  const { serviceId, orgRef, scheduleId } = useParams();
  const { height, width } = useWindowDimensions();

  const [displayList, setDisplayList] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(true);
  const [locationList, setLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));


  useEffect(() => {
    if (orgRef && orgDetails?.orgRef !== orgRef) {
      dispatch(fetchOrganizationById(orgRef))
    }
    if (serviceId && orgRef) {
      dispatch(fetchServiceById(serviceId))
      dispatch(fetchSchedulesByServiceId(serviceId))
      dispatch(fetchProvidersByServiceId(serviceId))
      setIsRefreshing(false);
    }
    if (scheduleId && orgRef) {
      dispatch(fetchScheduleById(scheduleId))
    }
  }, [serviceId, orgRef, scheduleId])

  const filterByLocation = (location = "All") => {
    setIsRefreshing(true);
    let filteredList = scheduleList.filter(item => item.status === "ACTIVE");
    if (location && location !== "All") {
      filteredList = filteredList.filter(item => item?.geoLocation?.fullAddress == location || item?.geoLocation?.name == location);
    }
    setSelectedLocation(location)
    sortSetDisplayList(filteredList);
    setIsRefreshing(false)
    //setTimeout(()=>setIsRefreshing(false),100);
  }

  const sortSetDisplayList = (list) => {
    let sortedList = list;
    if (sortedList.length > 1) {
      sortedList = sortedList.filter(item => item?.startDate);
      sortedList = sortedList.sort((a, b) => a?.slot?.startTime - b.slot.startTime)
    }
    setDisplayList(sortedList);
  }


  useEffect(() => {
    setIsRefreshing(true);
    let filteredList = scheduleList.filter(item => item.status === "ACTIVE");
    let locationList_ = filteredList.map(item => item?.geoLocation);
    if (locationList_.length) {
      locationList_ = locationList_.reduce((updatedList, item) => updatedList.find(i => i.name == item.name || i.fullAddress == item.fullAddress) ? updatedList : [...updatedList, item], [])
      locationList_ = [{ name: "All", iconName: 'checklist', }, ...locationList_]
      setLocationList(locationList_);
    } else {
      setLocationList([]);
    }
    filterByLocation();
  }, [scheduleList])

  useEffect(() => {
    setIsRefreshing(serviceDetailsLoading || scheduleListLoading || providerListLoading || scheduleDetailsLoading)
  }, [serviceDetailsLoading, scheduleListLoading, providerListLoading, scheduleDetailsLoading])

  useEffect(() => {
    if (scheduleId && scheduleDetails?.serviceId) {
      dispatch(fetchServiceById(scheduleDetails?.serviceId))
      dispatch(fetchProvidersByServiceId(scheduleDetails?.serviceId))
      sortSetDisplayList([scheduleDetails]);
      setIsRefreshing(false)
    }
  }, [scheduleDetails])

  if (!serviceId && !scheduleId) {
    return (
      <>
        <Alert sx={{ justifyContent: 'center', borderRadius: 3, my: 10 }} severity="error">Something went wrong</Alert>
      </>
    )
  }

  const trimLength = (string = "", length = 20) => {
    string = string?.length > length ? `${string.substring(0, length)}..` : string;
    return string;
  }

  return (
    <PrimaryLayout
      title={isMdDown ? "" : serviceDetails?.name}
      showLocation={false}
      showOrgLogo={isMdDown ? false : true}
      showBackButton={isMdDown ? true : false}
      appBarProps={isMdDown ?
        {
          position: 'fixed',
          color: "transparent",
          elevation: 0
        }
        :
        {}
      }
    >
      <GridContainer justifyContent="center" spacing={2}>
        <GridItem >
          <Box sx={{ m: 'auto', width: '100vw', maxWidth: { sm: "100%", md: '66.66%', lg: "360px" } }}>
            <ServiceCard isLoading={isRefreshing} />
          </Box>
        </GridItem>
        <GridItem xs={12} md={8} lg={8} xl={6} >
          <Box className="py-3 min-h-full">
            <Box spacing={1} className="py-2 w-100 flex flex-row justify-center">
              <HorizontalItems>
                {
                  isRefreshing ?
                    Array(parseInt(width / 150)).fill().map((item, idx) =>
                      <SkeletonCard key={idx} cardType="categories" />)
                    :
                    locationList.map((item, idx) => item &&
                      <Chip
                        key={idx}
                        icon={<Icon fontSize="small" >{item?.iconName ?? 'location_on'}</Icon>}
                        label={trimLength(item?.name || item?.fullAddress)}
                        variant="outlined"
                        color={(item?.name || item?.fullAddress) == selectedLocation ? "primary" : "default"}
                        onClick={() => filterByLocation(item?.name || item?.fullAddress)}
                        sx={{ marginRight: 1 }}
                      />
                    )
                }
              </HorizontalItems>
            </Box>
            {isRefreshing ?
              <GridContainer spacing={2} justifyContent="center">
                {Array(1).fill().map((item, idx) =>
                  <GridItem key={idx} xs={12}>
                    <SkeletonCard cardType="schedule" />
                  </GridItem>)
                }
              </GridContainer>
              :
              <GridContainer spacing={2} justifyContent="center">
                {displayList.length > 0 ?
                  displayList.map(scheduleData =>
                    <GridItem key={scheduleData?.id} xs={12} >
                      <ScheduleCard
                        scheduleData={scheduleData}
                        serviceData={serviceDetails}
                        providerList={providerList}
                        isLoading={scheduleListLoading}
                      />
                    </GridItem>
                  )
                  :
                  <>
                    <Alert sx={{ justifyContent: 'center', borderRadius: 3, my: 5 }} severity="error">No schedules available for this service</Alert>
                  </>
                }
              </GridContainer>
            }
          </Box>
        </GridItem>
      </GridContainer>
      <UserForm />
    </PrimaryLayout >
  )
}

export default Service; 