import axios from "axios";
import { refreshCurrentSession, setShowLoginForm } from "./authActions";
import { STRINGS } from "../../config/constants";

export const SET_GEOLOCATIONDATA = 'SET_GEOLOCATIONDATA';
export const SET_SHOW_USERFORM = 'SET_SHOW_USERFORM';
export const SET_SELECTED_AVAILABILITY = 'SET_SELECTED_AVAILABILITY';
export const SET_ASSESMENT_DATA = 'SET_ASSESMENT_DATA';
export const SET_ALERT = 'SET_ALERT';

const setAction = (type, payload) => ({
    type,
    payload,
});

export const fetchGeoLocationByCoordinates = (data) => async (dispatch) => {
    try {
        const latitude = data?.coords?.latitude;
        const longitude = data?.coords?.longitude;
        const accuracy = data?.coords?.accuracy;
        if (latitude && longitude) {
            const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`, { headers: { "Authorization": null } });
            if (response?.data?.address) {
                const geoData = {
                    coords: { latitude, longitude, accuracy },
                    address: response?.data?.address
                }
                dispatch(setAction(SET_GEOLOCATIONDATA, geoData));
            }
        }
    } catch (e) {
        console.log("Error: ", e);
    }
}

export const setGeoLocationInfo = (data) => (dispatch) => {
    dispatch(setAction(SET_GEOLOCATIONDATA, data));
}

export const setShowUserForm = (data) => (dispatch) => {
    dispatch(setAction(SET_SHOW_USERFORM, data));
}
export const setSelectedAvailability = (data) => (dispatch) => {
    dispatch(setAction(SET_SELECTED_AVAILABILITY, data));
}
export const setAssesmentData = (data) => (dispatch) => {
    dispatch(setAction(SET_ASSESMENT_DATA, data));
}

export const showAlert = (type = "success", message, snackProps = {}, alertProps = {}) => (dispatch) => {
    let data = { type, message, snackProps, alertProps }
    dispatch(setAction(SET_ALERT, data));
}


export const handleError = (e, type = "error") => async dispatch => {
    if (e?.message || e?.reason  ) {
        let message = e?.message || e?.reason;
        switch (message) {
            case STRINGS.API_FAIL_MSG:
                dispatch(showAlert(type, STRINGS.SOMETHNG_WENT_WRONG));
                break;
            case STRINGS.ORG_NOT_FOUND:
                dispatch(showAlert(type, message));
                break;
            case STRINGS.ACCOUNT_ALREADY_EXIST_PLEASE_LOGIN:
                dispatch(setShowLoginForm(true));
                dispatch(showAlert(type, message));
                break;
            default:
                dispatch(showAlert(type, message));
        }
    }
    if (e?.response?.status === 401) {
        const status = await dispatch(refreshCurrentSession());
        if (status && e?.config) {
            console.log("RETRYING API CALL ", e.config);
            return axios.request(e.config);
        }
    }
}
