import rootRoutes from './config/routes';
import './config/api';

import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import ThemeWrapper from './providers/ThemeProvider';
import ScrollToTop from './hooks/scrollToTop';
import store from './redux/store'
import { Provider } from 'react-redux'
import { AWS_CONFIG } from './config/auth';
import { Amplify } from 'aws-amplify';


Amplify.configure(AWS_CONFIG)

function App() {
  return (
    <>
      <Provider store={store.store}>
        <ThemeWrapper>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              {rootRoutes.map(route => (<Route key={route.name || route.path} {...route} />))}
            </Routes>
          </BrowserRouter>
        </ThemeWrapper>
      </Provider>
    </>
  );
}

export default App;
